<template>
    <div class="">

        <div class="contact">

        
            <div class="content lg:px-44 content-center gap-8">

                <!-- <div class="social w-16">
                    <div class="mt-10"><a href="#" class="bg-green-800 rounded-full px-3 py-2 text-3xl"><i class="las la-envelope"></i></a></div>
                    <div class="mt-10"><a href="#" class="bg-green-800 rounded-full px-3 py-2 text-3xl "><i class="lab la-facebook"></i></a></div>
                    <div class="mt-10"><a href="#" class="bg-green-800 rounded-full px-3 py-2 text-3xl"><i class="lab la-twitter"></i></a></div>
                    <div class="mt-10"><a href="#" class="bg-green-800 rounded-full px-3 py-2 text-3xl"><i class="lab la-linkedin"></i></a></div>
                </div> -->

                <div class="form">
                     <div class="lg:mt-16 title  font-semibold text-3xl">
                CONTACT
            </div>
                    <form class="py-8 items-center rounded lg:mt-7 w-94">
                        <div class="">
                            <label class="mb-3">Your Name:</label><br>
                            <input type="text" placeholder="Email"
                                class="input-widht mt-3 bg-white rounded w-full border border-gray-400 text-base px-4 py-2 mb-4" required><br>
                        </div>
                        <div class="">
                            <label class="mb-3">Your Email:</label><br>
                            <input type="text" placeholder="Email"
                                class="input-widht mt-3 bg-white rounded w-full border border-gray-400 text-base px-4 py-2 mb-4" required><br>
                        </div>
                        <div class="">
                            <label class="mb-3">Your Massage:</label><br>
                            <input type="text" placeholder="Massage"
                                class="input-widht mt-3 bg-white rounded w-full border border-gray-400 pb-16 text-base px-4 py-2 mb-4" required><br>
                        </div>
                        <button type="submit" value="Submit"
                            class="text-white  bg-green-600 border-0 py-2 px-8 focus:outline-none hover:bg-green-700 rounded text-md">Submit</button>
                    </form>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

export default ({

    data() {
      return {

        }
    },


})
</script>

<style scoped>

</style>
