<template>
    <div class="flex justify-bewteen items-center">
        <div v-if="projects" class="experience">
            <div
                class="text-black lg:py-3 grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-center text-center gap-10 tex-white justify-center project lg:px-10 w-full">
                <div v-for="(project, index) in projects" :key="index" class="text-black">

                    <div v-if="loading" class="loading w-full">
                        <ContentLoading />
                    </div>
                    <div v-else class="shadow-xl pb-3 border border-gray-300 rounded-md">
                        <div class="img relative overflow-hidden">
                            <a :href="project.project_url" target="_blank">
                                <img :src="url+project.image" alt="" srcset="">
                            </a>
                            <div class="ribbon">
                                <h4 class="py-2 bg-green-600 rounded text-sm px-8 text-white">
                                    {{project.category.name | strToUpper}}</h4>
                            </div>

                            <div class="mt-3">
                                <a :href="project.project_url" target="_blank"
                                    class="p-2 block bg-green-600 rounded mx-3 text-white">{{project.name | strToUpper}}</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
    export default {
        metaInfo: {
        title: 'Home',
        },
        data() {
            return {
                projects:'',

            }

        },

        mounted(){
            axios.get('projects').then(res =>{
                 this.projects = res.data
            })
        }

    }
</script>


<style scoped>
.men-image{
   width: 520px;
}
.project{
    color: white;
}

.ribbon{
       position: absolute;
    top: 38px;
    left: -42px;
    transform: rotate(315deg);
    left: 5;
    width: 200px;
}

.loading{
    width: 400px !important;
}
</style>